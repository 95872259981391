<template>
  <section class="ma-5">
    <v-row class="ml-1">
      <p class="text-h5 secondary--text">CDP</p>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="4">
        <v-text-field
          v-model="filters.nombre"
          label="No. de proceso"
          outlined
          clearable
          @click:clear="(filters.nombre = null), obtainListProcesos()"
          @keyup="filterName"
        />
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-select
          label="Estado"
          clearable
          :items="ctlEstados"
          item-value="id"
          item-text="nombre"
          outlined
          v-model="filters.id_seguimiento_proceso"
          @change="obtainListProcesos()"
        />
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-select
          label="Modalidad de compra"
          clearable
          :items="ctlModalidades"
          item-value="id"
          item-text="nombre"
          outlined
          @change="obtainListProcesos()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <data-table-component
          v-models:select="perPage"
          v-models:pagina="page"
          :headers="headers"
          :items="items"
          @paginar="paginate"
          :total_registros="totalRows"
        >
          <template #[`item.fecha_inicio_proceso`]="{ item }">
            <span>
              {{ moment(item.fecha_inicio_proceso).format("MMMM / YYYY") }}
            </span>
          </template>
          <template #[`item.fecha_contratacion`]="{ item }">
            <span>
              {{ moment(item.fecha_contratacion).format("DD/MM/YYYY") }}
            </span>
          </template>
          <template v-slot:[`item.suma_mercancia`]="{ item }">
            {{
              Intl.NumberFormat("en-US", {
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(item.suma_mercancia)
            }}
          </template>
          <template v-slot:[`item.estado`]="{ item }">
            <v-chip label dark :color="item.seguimiento_proceso?.color">
              {{ item.seguimiento_proceso?.nombre || "" }}
            </v-chip>
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon @click="$router.push({name: 'emision-cdp-procesos', params:{idProceso: item.id}})" v-bind="attrs" v-on="on" color="secondary">
                  mdi-eye
                </v-icon>
              </template>
              <span> Mostrar </span>
            </v-tooltip>
          </template>
        </data-table-component>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import DataTableComponent from "../../components/DataTableComponent.vue";
import moment from "moment";
export default {
  name: "CDPListView",
  components: {
    DataTableComponent,
  },
  data: () => ({
    headers: [
      {
        text: "Código",
        value: "codigo_proceso",
      },
      {
        text: "Proceso",
        value: "nombre_proceso",
      },
      {
        text: "Modalidad de compra",
        value: "forma_contratacion.nombre",
        align: "right",
      },
      {
        text: "Mes programado",
        value: "fecha_inicio_proceso",
        align: "right",
      },
      {
        text: "Fecha solicitado",
        value: "fecha_contratacion",
        align: "right",
      },
      {
        text: "Monto programado($)",
        value: "suma_mercancia",
        align: "center",
      },
      {
        text: "Estado",
        value: "estado",
        align: "center",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      },
    ],
    items: [],
    filters: {
      id_seguimiento_proceso: 9,
      id_forma_contratacion: null,
      nombre: null,
    },
    totalRows: 0,
    page: 1,
    perPage: 10,
    ctlModalidades: [],
    ctlEstados: [
      {
        id: 9,
        nombre: "Solicitud de CDP",
      },
      {
        id: 10,
        nombre: "CDP asignado",
      },
      {
        id: "9,10",
        nombre: "Todos",
      },
    ],
    timer: null,
  }),

  methods: {
    paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      this.perPage = cantidad_por_pagina;
      this.page = pagina;
      this.obtainListProcesos();
    },
    filterName() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(async () => {
        await this.obtainListProcesos();
      }, 500);
    },
    async fetchModalidades() {
      const response = await this.services.Paac.getModalidades();
      if (response) {
        this.ctlModalidades = response.data.forma_contratacion;
      }
    },

    async obtainListProcesos() {
      const { status, data, headers } =
        await this.services.Paac.getListProcesos({
          ...this.filters,
          page: this.page,
          per_page: this.perPage,
        });
      if (status == 200) {
        this.items = data.procesos;
        this.totalRows = Number(headers.total_rows);
      }
    },
  },
  created() {
    this.obtainListProcesos();
    this.fetchModalidades();
  },
};
</script>
